@import '../../../styles/variable';

.container {
	height: 421px;
}

.icon {
	margin-top: 37px;
	margin-bottom: 19px;
	display: flex;
	justify-content: center;
}

.subtitle {
	font-family: $ff;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	text-align: center;

	max-width: 227px;

	margin: 15px auto;

	color: rgba($color: $text, $alpha: 0.7);
}

.time {
	font-family: $ff_b;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;

	margin: 0 auto;
	color: $text;

	display: flex;
	justify-content: center;
}

.btns {
	position: absolute;
	bottom: 24px;
	width: 88%;
}

.btn {
	font-family: $ff_b;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;

	padding: 16px 0;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	color: $text;
	background: linear-gradient(180deg, #D3DDEB 0%, #A3B6CF 100%);
	border-radius: 8px;
}

.btn_complete {
	margin-top: 16px;
	background: linear-gradient(180deg, #EE4F7F 0%, #BB133B 100%);
	border-radius: 8px;

	padding: 16px;
	text-align: center;
}

.btn_complete a {
	font-family: $ff_b;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: $white;

	text-transform: uppercase;
}

.btn_refuse {
	background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
	border-radius: 8px;
	text-align: center;

	padding: 16px;
}

.btn_refuse a {
	font-family: $ff_b;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: $white;

	text-transform: uppercase;
}

.pop_up {
	position: fixed;
	z-index: 100000;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	height: 100%;
	background: rgba(14, 61, 87, 0.76);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pop_up__container {
	max-width: 450px;
	margin: 0 16px;
	position: relative;
	padding: 38px 24px 24px;
	background: $white;

	border-radius: 12px;
}

.pop_up__cross {
	position: absolute;
	top: 20px;
	right: 20px;
}

.pop_up__cross img {
	background-color: white;
}

.input {
	margin-top: 26px;
	position: relative;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.input input {
	padding: 15px 0 13px 45px;
	background: rgba(237, 243, 252, 0.7);
	border: 1px solid #D5DEEB;
	border-radius: 8px;

	width: 87%;
}

.input input {
	padding: 15px 0 13px 45px;
	background: rgba(237, 243, 252, 0.7);
	border: 1px solid #D5DEEB;
	border-radius: 8px;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	width: 87%;
}

.input input:active,
.input input:focus {
	outline: none;
	color: $text;
}

.input input::placeholder {
	font-family: $ff;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;

	color: rgba($color: $text, $alpha: 0.2);
}

.check_res {
	align-items: center;
	background: #d3ddeb;
	border-radius: 8px;
	flex-direction: row;
	justify-content: center;
	margin-top: 26px;
	padding: 16px 14px 16px 48px;
	position: relative;
	overflow: hidden;
}

.check_res svg {
	position: absolute;
	top: 20px;
	left: 14px;
}

.input svg {
	position: absolute;
	top: 20px;
	left: 14px;
}

.check_res_email {
	position: relative;
	color: rgba(14, 61, 87, .7);
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 54vw;
}

.pop_up__btn,
.check_res_pop_up__btn {
	margin-top: 32px;
	padding: 16px 0;
	background: linear-gradient(180deg, #D3DDEB 0%, #A3B6CF 100%);
	border-radius: 8px;
	width: 100%;

	text-align: center;
	font-family: $ff_b;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;

	color: $white;
}

.check_res_pop_up__btn {
	background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
}

.pop_up__btn_active {
	background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
}

.hidden {
	display: none;
}