@import '../../styles/variable';


.container {
  min-height: 432px;
}

.auto {
  margin-top: 32px;
  width: 100%;
  border-radius: 8px;
  background: $gray;
}

.auto img {
  width: 100%;
  border-radius: 8px;
}

.auto_info {
  padding: 12px
}

.auto_info span {
  font-family: $ff_m;
  font-weight: 450;
  font-size: 18px;
  line-height: 16px;
}

.auto_info span:first-child {
  color: $text;
  margin-right: 8px;
}

.auto_info span:nth-child(2) {
  color: rgba($color: $text, $alpha: 0.7);
}

.btns {
  position: absolute;
  bottom: 24px;
  width: 86%;
}

.btn {
  display: block;
  padding: 16px 0;
  width: 100%;

  border-radius: 8px;
  text-align: center;

  cursor: pointer;

  font-family: $ff_b;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: $white;
}

.btn:first-child {
  background: linear-gradient(180deg, #D3DDEB 0%, #A3B6CF 100%);
  margin-right: 16px;
}

.btn:nth-child(2) {
  background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
}


.btn:first-child {
  color: $text;
}

.img_container {
  position: relative;
  width: 100%;
  height: 216px;
  overflow: hidden;
  border-radius: 8px;
}

.img_container img {
  //width: 193%;
  height: 100%;
  position: relative;
  object-fit: contain;
  //object-position: top -54px right 110px;
}
