.demo-col{padding: 0.5rem; background: #2F333D; color: $gray; text-align: center;}

.row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	margin: 0 ($grid_pad*-1)+px;
	flex-wrap: wrap;
	@include media ('tm') {
		& {
			margin: 0 -8px;
		}
	}
}


.row + .row{margin-top: ($grid_pad*2)+px;}
.row.center{align-items: center;}
.row.space-between{justify-content: space-between;}
.row.stretch{align-items: stretch;}
.row.row-reverse{flex-direction: row-reverse;}
.row.row_no-flex{display: block;}
.row.row_no-flex *[class*="col-"]{float: left;}
.row.no-gutters{margin-right: 0; margin-left: 0;}

.no-gutters > .col,
.no-gutters > [class*="col-"] {padding-right: 0; padding-left: 0;}

@include gridColumn();

/*
@include gridColumn(10);
@include gridColumn(8);
*/
