@import '../../styles/variable';

.container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: 100%;
  min-height: 54.5vh;
}

.containerTop {
  width: 100%;
}

.img {
  display: flex;
  justify-content: center;
  margin: 10px auto 22px;
}

.subtitle {
  padding: 24px 8px;
  text-align: center;

  font-family: $ff_m;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;

  color: rgba($color: $text, $alpha: 0.7);
}

.btns {
  bottom: 24px;
  left: 24px;
  right: 24px;
  width: 87%;
  margin: 0 auto;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 12px 0;
  text-align: center;
  text-transform: uppercase;
  font-family: $ff_b;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  svg {
    margin-right: 4px;
  }
}

.btn:first-child {
  background: linear-gradient(180deg, #FFEB84 0%, #FFAA2B 100%);

}

.btn:first-child a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn:first-child a span {
  margin-left: 12px;
}

.btn:nth-child(2) {
  margin-top: 16px;
  background: linear-gradient(180deg, #D3DDEB 0%, #A3B6CF 100%);
}

.btn:nth-child(3) {
  margin-top: 16px;
}

.btn:nth-child(2) a {
  padding: 4px 0;
}

.btn__main_screen {
  background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%) !important;
}

.btn__main_screen a {
  color: $white;
}

@media screen and (max-height: 830px) {
  .fixHeight {
    height: 82vh;
  }
}

@media screen and (max-height: 480px) {
  .wrapper {
    width: 83%;
  }
}

