@import '../../../styles/variable';

.header {
  padding: 24px 16px;
  background: linear-gradient(0deg, $gradient_header_start 0%, $gradient_header_end 102.23%);
}

.operator_btn {
  padding: 8px 22px;

  background: rgba(255, 255, 255, 0.22);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;

}

.operator_btn__text {
  font-family: $ff_b;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;

  color: $white;

  margin-left: 8px;
}

.parking_address {
  display: flex;
  margin-top: 24px;
  padding: 12px 17px;
  background: rgba(9, 153, 158, 0.5);
  border-radius: 12px;
}
.parking_address__p {
  display: flex;
  align-items: center;
  flex: 0 0 42px;
}

.parking_address__items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  margin-left: 12px;
}

.parking_address__title {
  font-family: $ff_b;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}

.parking_address__text {
  font-family: $ff_m;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;

  color: $white;
}

@media screen and (max-width: 375px) {
  .parking_address__text {
    max-width: 200px;
  }
}

@media screen and (max-width: 355px) {
  .operator_btn {
    padding: 8px 8px;
  }
}

@media screen and (max-width: 330px) {
  .operator_btn {
    padding: 6px 7px;
  }

  .operator_btn__text {
    font-size: 13px;
  }

  .operator_btn div img {
    transform: scale(0.8);
  }
}
