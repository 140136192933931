@import '../../../styles/variable';

.items {
  padding: 26px 0 24px;
}


.item {
  position: relative;
  padding: 24px 0;
  text-align: center;

  border: 2px solid #D3DDEB;
  border-radius: 8px;
  .itemName {
    font-family: $ff_m;
    font-weight: 700;
    font-size: 20px;
    color: #0E3D57;
    padding: 16px 0 0;
  }
  .itemDescription {
    font-family: $ff_m;
    font-weight: 450;
    font-size: 14px;
    color: rgba(14, 61, 87, 0.7);
    padding: 8px 0 0;
  }
}

.item__active {
  border: 2px solid $green;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.item:nth-child(2) {
  padding: 52px 38px;

  margin-top: 16px;
}

.label_wrap {
  position: absolute;
  right: 8px;
  top: 8px;
}

.item label input[type="radio"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.radio {
  display: none;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radio__active {
  display: inline-block;
  height: 24px;
  width: 24px;
  background: #fff;
  margin-right: 4px;
  border-radius: 12px;
  cursor: pointer;

  background: $green;
  background-image: url('../../../../public/imgs/check.svg');
  background-size: 19px 13px;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid $green;
}

.btnWrap {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: 100%;

  .wrapItem {
    width: 100%;
  }
}

.btn {
  display: block;
  background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
  border-radius: 8px;
  margin: 0 auto;

  padding: 16px 0;
  text-align: center;
  bottom: 24px;
  width: 100%;

  font-family: $ff_b;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: $white;
}

.btn_notactive {
  opacity: 0.5;
}
