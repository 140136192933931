@import '../../styles/variable';

.wr {
	margin: 0px 0 30px;
}

.input {
	margin-top: 16px;
	position: relative;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.input input {
	padding: 15px 0 13px 45px;
	background: rgba(237, 243, 252, 0.7);
	border: 1px solid #D5DEEB;
	border-radius: 8px;

	width: 87%;
}

.input input {
	padding: 15px 0 13px 45px;
	background: rgba(237, 243, 252, 0.7);
	border: 1px solid #D5DEEB;
	border-radius: 8px;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	width: 87%;
}

.input input:active,
.input input:focus {
	outline: none;
	color: $text;
}

.input input::placeholder {
	font-family: $ff;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;

	color: rgba($color: $text, $alpha: 0.2);
}

.input svg {
	position: absolute;
	top: 20px;
	left: 14px;
}

.pop_up__btn {
	margin-top: 32px;
	padding: 16px 0;
	background: linear-gradient(180deg, #D3DDEB 0%, #A3B6CF 100%);
	border-radius: 8px;
	width: 100%;

	text-align: center;
	font-family: $ff_b;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;

	color: $white;
}