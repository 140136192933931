html, 
body {
  margin: 0; 
  padding: 0; 
  font-family: $ff; 
  font-weight: 700;
  max-width: 100%; 
  -webkit-tap-highlight-color: transparent; 
}

body {
  cursor: default; 
  position: relative;
}

button {
  outline: 0;
  border: 0;
  padding: 0;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: $text;
}