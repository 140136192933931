@use 'sass:math';
@use "sass:map";
@use "sass:string";
@import "./variable";

//Mixins Library

/* rem */
@function rem( $pxsize ) {
	@if ( $pxsize and $fs ) {
		@return math.div( $pxsize, $fs ) * 1rem;
	}
}


/* column generator */
@mixin gridColumn( $total-columns: 12 ){

	$prefix: '';
	@if $total-columns != 12 { 
		$prefix: '-#{$total-columns}';
	}

	// column
	%grid-column {
		width: 100%;
		padding-right: $grid_pad+px;
		padding-left: $grid_pad+px;
	}

	@include media ( 'tm' ){
		%grid-column {
			width: 100%;
			padding-right: 8px;
			padding-left: 8px;
		}
	}

	@for $i from 1 through $total-columns {
		
		$percent: math.div( $i, $total-columns );
		@if $percent == 0% { 
			$percent: 0; 
		}

		.col#{$prefix}-#{$i} { 
			@extend %grid-column;
			flex: 0 0 $percent*100%;
			max-width: $percent*100%;
			//overflow: hidden;
		}

	}

	// offset
	@for $i from 0 through $total-columns - 1 {

		$percent: math.div( $i, $total-columns );
		@if $percent == 0% { 
			$percent: 0; 
		}

		.offset#{$prefix}-#{$i} { 
			margin-left: $percent;
		}
		.offset#{$prefix}-right-#{$i} { 
			margin-right: $percent;
		}

	}

}


// time step (ms), all number, prefix, CSS property
@mixin animationDelay( $time: 50, $all_number: 30, $prefix: 'as', $property: 'transition-delay' ){

	@for $i from 0 through $all_number {
		
		$time_res: ($i*$time)+ms;

		@if $i == 0 {
			$time_res: 0; 
		}

		.#{$prefix}-#{$i} {
			#{$property}: $time_res;
		}
		
	}

}


/* input placeholder */
@mixin inputPlaceholder($color, $font-weight, $opacity) {
	&::-webkit-input-placeholder {
		color: $color;
		font-weight: $font-weight;
		opacity: $opacity;
	}

	&:-moz-placeholder {
		color: $color;
		font-weight: $font-weight;
		opacity: $opacity;
	}

	&::-moz-placeholder {
		color: $color;
		font-weight: $font-weight;
		opacity: $opacity;
	}

	&:-ms-input-placeholder {
		color: $color;
		font-weight: $font-weight;
		opacity: $opacity;
	}
	
}


// media

@mixin media( $size, $debug: false ) {

	$media_short: ();

	@each $name, $value in $media {
		$media_short_name: string.slice($name, 1, 1) + string.slice($name, -1, -1);
		$media_short: map.set( $media_short, $media_short_name, $value );
	}

	@if $debug {
		@debug "Допустимо";
		@debug $media;
		@debug "или";
		@debug $media_short;
		@debug "Получено - " + $size;
	}
	
	$media_sizes: map.get( $media, $size );
	$short_name: string.slice($size, 1, 1) + string.slice($size, -1, -1);
	
	@if $debug {
		@debug $short_name;
		@debug $media_sizes;
	}

	@if ( $media_sizes or map.get( $media_short, $size ) ) {
		
		@if $debug {
			@debug "### все хорошо, выключай debug / @include media( " + $size + " ) ###";
		}

		@if ( $media_sizes ){
			@media ( max-width: ( $media_sizes + px ) ){
				@content;
			}
		} @else {
			@media ( max-width: ( map.get( $media_short, $size ) + px ) ){
				@content;
			}
		}
		
	} @else {
		@debug "------------- что то пошло не так, включай debug / @include media( " + $size + ", debug ) -------------";
	}

}

