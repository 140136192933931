@import '../../styles/variable';

.section {
  position: relative;
  background-color: $light_gray;
  padding: 72px 16px 96px;
}

.img {
  position: absolute;
  top: -32px;
  left: 24px;
}

.img svg {
  padding: 4px;
  background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
  box-shadow: 0 4px 4px rgba(204, 219, 241, 0.5);
  border-radius: 12px;
}

.form {
  background: $white;

  box-shadow: 0 4px 4px rgba(204, 219, 241, 0.5);
  border-radius: 12px;
  padding: 62px 24px 40px;
}

.form_input {
  position: relative;
}

.form_input_btn {
  height: 48px;
  padding: 12px;
  background-color: rgba(237, 243, 252, 0.7);
  border: none;
  border-radius: 8px 0 0 8px;
  border-right: 1px solid #D5DEEB;
  position: absolute;
  left: 1px;
}

.form_input_btn_country {
  padding: 14px 19px;
  background-color: $gray;
  border: 1px solid $gray;
  border-radius: 8px;

  display: flex;
  justify-content: center;

  position: absolute;
  top: 74%;

  display: none;
}

.country_btn_w_error {
  top: 108% !important;
}

.form_input_btn_country_active {
  display: unset;
}

.form_input_btn div svg:last-child {
  margin-left: 6px;
}

.form input[type="text"] {
  background: rgba(237, 243, 252, 0.7);
  border: 1px solid #D5DEEB;
  border-radius: 8px;
  width: 74%;
  padding: 12px;
  padding-left: 78px;

  margin: 34px 0;

  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: $text;
  text-transform: uppercase;
}

.input_error {
  border: 1px solid $red !important;
  margin: 34px 0 0 !important;
}

.btn_w_error {
  top: 35.2px;
}

.form input[type="text"]::placeholder {
  color: rgba($color: $text, $alpha: 0.2);
}

.form_input input[type="text"]:active,
.form_input input[type="text"]:focus {
  border: 1px solid #10C6BE;

  outline: none;
}

.form_input input[type="text"]:active,
.form_input input[type="text"]:focus {
  outline: none;
}

.form_input_btn:hover,
.form_input_btn:active,
.form_input_btn:focus {
  background: #D3DDEB;
}


input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 1px solid #D5DEEB;
  margin-right: 4px;
  border-radius: 4px;
  cursor: pointer;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.checkbox__active {
  background: $green;
  background-image: url('../../../public/imgs/check.svg');
  background-size: 19px 13px;
  background-repeat: no-repeat;
  background-position: center;
  border-color: $green;
  cursor: pointer;
}

.form_checkbox__text {
  display: inline-block;
  max-width: 261px;
  margin-left: 16px;

  font-family: $ff1;
  font-weight: 450;
  font-size: 18px;
  line-height: 16px;

  color: $text;
}

.form_checkbox__personal_data {
  color: $green;
  background: none;

  font-family: $ff1;
  font-weight: 450;
  font-size: 18px;
  line-height: 16px;
}


.form_btn {
  padding: 16px;
  margin-top: 32px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
  opacity: 0.5;
  border-radius: 8px;
}

.form_btn__active {
  opacity: 1;
}

.form_btn span {
  margin-left: 10px;
  font-family: $ff_b;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: $white;
}

.error {
  color: $red;

  margin-top: 3px;
  margin-bottom: 13px;

  font-family: $ff1;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.hidden {
  display: none;
}

@media  screen and (min-height: 830px) {
  .fixHeight {
      height: 46.2vh;
  }
}

@media screen and (max-width: 382px) {
  .form_checkbox__text {
    max-width: 254px;
  }

  .form_checkbox__personal_data {
    text-align: left;
  }
}

@media screen and (max-width: 380px) {
  .form_checkbox__text {
    max-width: 154px;
  }
}
