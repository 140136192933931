@import '../../styles/variable';

.container {
  min-height: 512px;
}

.check {
  margin-top: 34px;
}

.check_start {
  object-fit: cover;
}

.check_start svg {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: auto;
  padding: 0;
}

.check__price {
  margin: 0 6%;
  padding: 0 16px 22px;

  background: #DDEAFD;
}

.check__price_title {
  font-family: $ff1;
  font-weight: 450;
  font-size: 18px;
  line-height: 13px;

  color: rgba($color: $text, $alpha: 0.7);
}

.check__price_price {
  margin-top: 7px;

  font-family: $ff1;
  font-weight: 500;
  font-size: 46px;
  line-height: 32px;

  color: $text;
}

.check__price_subscribe {
  margin: 8px 0 0;
}

.check__price_subscribe_text {
  font-family: $ff1;
  font-weight: 450;
  font-size: 15px;
  line-height: 13px;

  color: rgba($color: $text, $alpha: 0.5);

  display: flex;
  flex-wrap: wrap;
}

.check__price_subscribe_btn {
  display: block;
  padding: 5px 8px;

  border: 1px solid rgba($color: $text, $alpha: 0.2);
  border-radius: 4px;

  margin-left: 8px;

  font-family: $ff1;
  font-weight: 450;
  font-size: 15px;
  line-height: 13px;
}

.check_info {
  padding: 19px 13px 26.25px 11.5px;
  margin: -1px 6% 0 6%;

  background: #EDF3FC;
}

.check_info_item {
  padding-bottom: 10px;
  padding-top: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  border-bottom: 0.53px solid #E1E6EE;
}

.check_info_item:first-child {
  padding-top: 0;
}

.check_info_item:last-child {
  border-bottom: none;

}

.check_info_item_title {
  font-family: $ff_m;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;

  color: rgba($color: $text, $alpha: 0.7);
}

.check_info_item_time {
  font-family: $ff;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: $text;
}

.check_info_item:first-child .check_info_item_time {
  margin-left: 12.5px;
}

.check_info_item:nth-child(2) .check_info_item_time {
  margin-left: 22.5px;
}

.check_info_item:nth-child(3) .check_info_item_time {
  margin-left: 24.5px;
}

.check_info_item_time span {
  font-size: 18px;
  color: rgba($color: $text, $alpha: 0.7);
}

.check_info_btn {
  display: block;
  background-color: #EDF3FC;
  font-family: $ff_b;
  font-weight: 700;
  font-size: 16px;
  line-height: 13px;
  text-transform: uppercase;
  color: $white;
  background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
  box-shadow: 0 2.10714px 2.10714px rgba(204, 219, 241, 0.5);
  border-radius: 8px;
  padding: 21px 0;
  text-align: center;
}

.btn_notactive {
  opacity: 0.5;
}

.check_end {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.check_info_subscription_item {
  padding-bottom: 10px;
  padding-top: 6px;

  border-bottom: 0.53px solid #E1E6EE;
}

.check_info_subscription_item:first-child {
  padding-top: 0;
}

.check_info_subscription_item:last-child {
  border: none;
}

.check_info_subscription_item_title {
  font-family: $ff_m;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;

  color: rgba($color: $text, $alpha: 0.7);
}

.check_info_subscription_item_time {
  font-family: $ff;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: $text;
}

.hidden {
  display: none;
}

@media screen and (max-width: 480px) {
  .check_end {
    margin: -2% 6% 0 6%;
    object-fit: cover;
  }
}

@media screen and (max-width: 400px) {
  .check_end {
    margin: -3% 6% 0 6%;
    object-fit: cover;
  }
}

@media screen and (max-width: 380px) {
  .check_info_item {
    flex-direction: column;
  }

  .check_info_item:first-child .check_info_item_time {
    margin-left: 0;
  }

  .check_info_item:nth-child(2) .check_info_item_time {
    margin-left: 0;
  }

  .check_info_item:nth-child(3) .check_info_item_time {
    margin-left: 0;
  }
}

@media screen and (max-width: 350px) {
  .check_end {
    margin: -4% 6% 0 6%;
    object-fit: cover;
  }
}
