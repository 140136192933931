/* base fonts */
$ff: 'Futura New', 'monospace', sans-serif; /* font family */
$ff1: 'Futura New Demi';
$ff_b: 'Futura New Bold';
$ff_m: 'Futura New Medium';

$fs: 18; /* font size */
$lh: 1.6; /* line height 1.6 */

/* grid */
$space: 10; /* spacing grid */
$grid_pad: 15; /* padding column */
$wr_pad: 20; /* wrapper padding */
$big_pad: 8.43; /* big padding */
$page_wr: 1320; /* max-width content */

$media: (
  desktop_l: 2560, /* desktop long */
  desktop_m: 1920, /* desktop middle */
  desktop_s: 1440, /* desktop small */
  tablet_l: 1280, /* tablet long */
  tablet_m: 1024, /* tablet middle */
  tablet_s: 900, /* tablet small */
  mobile_l: 768, /* mobile long */
  mobile_m: 475, /* mobile middle */
  mobile_s: 375, /* mobile small */
);

/* color */
$white: #FFFFFF;
$text: #0E3D57;
$green: #10C6BE;
$blue: #09999E;
$red: #F57272;

$gradient_header_start: #0CE7A7;
$gradient_header_end: #00AABA;

$gradient_green_start: #00E6A3;
$gradient_green_end: #06C1D2;

$gradient_gray_start: #D3DDEB;
$gradient_gray_end: #A3B6CF;

$gradient_yellow_start: #FFEB84;
$gradient_yellow_end: #FFAA2B;

$gradient_red_start: #EE4F7F;
$gradient_red_end: #BB133B;

$light_gray: #EDF3FC;
$gray: #D3DDEB;
