@import '../../../../styles/mixins';

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin: 0 auto;
  flex-wrap: nowrap;
}


.row.center {
  align-items: center;
}

.row.nowrap {
  flex-wrap: nowrap;
}

.row.space_between {
  justify-content: space-between;
}

.row.stretch {
  align-items: stretch;
}

.row.row-reverse {
  flex-direction: row-reverse;
}

.row.row_no-flex {
  display: block;
}

.row.row_no-flex *[class*="col-"] {
  float: left;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
