@import '../../../styles/variable';

.item {
  position: relative;
  max-width: 140px;
  height: 127px;
  background-color: $gray;
  border-radius: 8px;

  margin-top: 20px;

  cursor: pointer;
  width: 137px;

}

.item img {
  width: 135px;
  height: 98px;
  border-radius: 8px;
}

.item_info {

  padding: 4px 17px 6px 12px;
  display: flex;
  justify-content: space-between;
}

.item_info span {
  font-family: $ff_m;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
}

.item_info span:first-child {
  color: $text;
}

.item_info span:nth-child(2) {
  color: rgba($color: $text, $alpha: 0.7);
}

.active {
  box-sizing: border-box;
  border: 3px solid $green;
}

.active img {
  border-radius: 5px;
  width: 132px;
  margin-top: -0.8px;
  margin-left: -1px;
}

.active .item_info {
  padding: 4px 12px 4px 7px;
}

.active_click {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: $green;
  background-image: url('../../../../public/imgs/check.svg');
  background-size: 15px 12px;
  background-repeat: no-repeat;
  background-position: center;

  position: absolute;
  top: 8px;
  right: 8px;

  transition: all 0.2s ease;

}

.subscription__item input[type="radio"] {
  width: 140px;
  height: 120px;
  position: absolute;
  z-index: 12;
}
