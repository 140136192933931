@import '../../../styles/variable';

.footer {
  padding: 16px;
  background-color: $light_gray;
}

.contacts {
  padding: 20px 0;
  background: $gray;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 12px;
}

.contact_title {
  font-family: $ff_b;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  color: rgba($color: $text, $alpha: 0.4);

  margin-bottom: 5px;
}

.contact_mail {
  font-family: $ff_m;
  font-weight: 450;
  font-size: 18px;
  line-height: 24px;

  color: $text;

  margin-top: 10px;
}

.contact_mail div svg {
  margin-right: 10px;
}

.contact_mail div div {
  margin-top: -3px;
}
