@import '../../styles/variable';

.payment {
  padding-top: 64px;
  background-color: $light_gray;
  padding: 64px 16px 0;

}

.payment__methods {
  padding: 30px 24px 24px;
  background-color: $white;
  box-shadow: 0 4px 4px rgba(204, 219, 241, 0.5);
  border-radius: 12px;
}

.payment__methods_info_btn {
  position: absolute;
  top: 12px;
  right: 28px;
  background: transparent;
  color: $text;
}

.payment__methods_info_btn img {
  opacity: 0.5;
}

.payment__methods_info_btn:hover img {
  opacity: 1;
}

.payment__methods_info_btn_active img{
  opacity: 1;
}

.payment__methods_info {
  max-width: 264px;
  position: absolute;
  right: 16px;

  display: none;
}

.payment__methods_info_del_hidden {
  display: unset;
}

.payment__methods_info__triangle {
  position: absolute;
  top: 12px;
  right: 13px;
}

.payment__methods_info__text {
  font-family: $ff_m;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: $text;
  background-color: #E0E7F2;
  border-radius: 4px;
  padding: 12px;

  margin-top: 28px;
}

.payment__methods__title {
  margin-top: 6px;
  margin-bottom: 34px;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: $text;
}

.payment__methods_item_grs {
  display: block;
  padding: 16px 24px;
  background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
  border-radius: 8px;
  margin-top: 34px;
}

.payment__methods_item_grs:hover {
  background: linear-gradient(180deg, #0CC690 0%, #0B9EAC 100%);
}

.payment__methods_item_grs a span img,
.payment__methods_item_phone a span img {
  padding: 4px;
  background-color: rgba(7, 118, 124, 0.2);
  border-radius: 12px;
}

.payment__methods_item_phone {
  display: block;
  margin-top: 24px;
  padding: 16px 24px;
  background: linear-gradient(180deg, #FFD584 0%, #F89C14 100%);
  border-radius: 8px;
  margin-bottom: 24px;
}

.payment__methods_item_phone:hover {
  background: linear-gradient(180deg, #E9D258 0%, #CF810D 100%);
}

.payment__methods_item_phone a span img {
  background-color: rgba(211, 89, 19, 0.2);
}

.payment__methods_item_grs_text,
.payment__methods_item_phone_text {
  font-family: $ff_b;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-left: 24px;
  max-width: 50%;
  text-transform: uppercase;
  color: $white;
}

.payment__methods_item_phone_text {
  color: $text;
}

.payment___buy_subscription {
  padding: 32px 0 16px;
}

.payment___buy_subscription:hover a div .payment___buy_subscription_link_txt {
  color: $green;
}

.payment___buy_subscription_link div {
  justify-content: center;
}

.payment___buy_subscription_link div span img {
  padding: 4px;
  background-color: rgba(211, 221, 235, 1);
  border-radius: 12px;
  margin-right: 24px;
}

.payment___buy_subscription_link_txt {
  font-family: $ff_b;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
}

@media  screen and (min-height: 830px) {
  .payment {
      height: 58vh;
  }
}
