@import '../../styles/variable';

.items {
  margin: 24px 0 26px;
}

.btn {
  display: block;
  background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
  border-radius: 8px;
  padding: 16px 0;
  text-align: center;
  color: $white;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  font-family: $ff_b;
}
