@import '../../styles/variable';

.container {
  background: $light_gray;
  position: absolute;
  z-index: 1000;
  top: 24px;
  right: 16px;
  left: 16px;
}

.wrapper {
  background: $white;
  border-radius: 12px;
  padding: 30px 24px 24px;
  margin: 0 auto;
  height: 478px;
  position: relative;
}

.scrollbar_container {
  position: relative;
  margin: 0 8px;
  max-height: 54vh;
  max-height: 448px;
  overflow-y: scroll;
}

.scrollbar_container::-webkit-scrollbar {
  width: 6px;
}

.scrollbar_container::-webkit-scrollbar-track {
  background-color: rgba($color: $gray, $alpha: 0.3);
  border-radius: 24px;
}

.scrollbar_container::-webkit-scrollbar-thumb {
  background: $gray;
  border-radius: 24px;
}

.btn {
  padding: 8px 0;
  margin-bottom: 22px;
}

.btn button {
  font-family: $ff1;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: $text;

  padding: 8px;
  border-radius: 6px;


  background: rgba($color: $gray, $alpha: 0.4);
}

.text {
  position: relative;
  font-family: $ff;
  color: $text;

  max-width: 288px;

  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  opacity: 0.7;

  margin-top: 16px;
}

.text_attenuation {
  height: 96px;
  width: 82%;
  bottom: 25px;
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 73.96%);
}

.hidden {
  display: none;
}