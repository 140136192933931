@font-face {
  font-family: 'Futura New ';
  src: local('Futura New Book Reg'), local('Futura-New-Book-Reg'),
      url('../fonts/FuturaNewBook-Reg.woff2') format('woff2'),
      url('../fonts/FuturaNewBook-Reg.woff') format('woff'),
      url('../fonts/FuturaNewBook-Reg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New Demi';
  src: local('Futura New Demi Reg'), local('Futura-New-Demi-Reg'),
      url('../fonts/FuturaNewDemi-Reg.woff2') format('woff2'),
      url('../fonts/FuturaNewDemi-Reg.woff') format('woff'),
      url('../fonts/FuturaNewDemi-Reg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New Bold';
  src: local('Futura New Bold Reg'), local('Futura-New-Bold-Reg'),
      url('../fonts/FuturaNewBold-Reg.woff2') format('woff2'),
      url('../fonts/FuturaNewBold-Reg.woff') format('woff'),
      url('../fonts/FuturaNewBold-Reg.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New Medium';
  src: local('Futura New Medium Reg'), local('Futura-New-Medium-Reg'),
      url('../fonts/FuturaNewMedium-Reg.woff2') format('woff2'),
      url('../fonts/FuturaNewMedium-Reg.woff') format('woff'),
      url('../fonts/FuturaNewMedium-Reg.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}