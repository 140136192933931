@import '../../styles/variable';

.grz_auto {
  background-color: $light_gray;
  padding: 24px 16px 8px;
  position: relative;
  min-height: 60vh
}

.fixHeight {
  height: 498px;
}

.wrapper {
  background-color: $white;
  box-shadow: 0 4px 4px rgba(204, 219, 241, 0.5);
  border-radius: 12px;

  padding: 30px 24px 24px;
}

.items {
  margin-top: 6px;
  margin-bottom: 56px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn {
  display: block;
  padding: 16px;
  text-align: center;
  width: 91%;

  background: linear-gradient(180deg, #00E6A3 0%, #06C1D2 100%);
  border-radius: 8px;
  cursor: pointer;

  font-family: $ff1;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;

  color: $white;
  text-transform: uppercase;
}
