@import '../../../styles/variable';


.container {
    height: 512px;
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 16px;
    background: white;
    z-index: 1000;
}

.hidden {
    display: none;
}

.loading {
    position: relative;
    padding: 56px 0;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    margin-top: 26px;
    overflow: hidden;
}

.loading_back {
    height: 142px;
    width: 142px;
    border-radius: 85px;
    border: 14px solid #F9D48C;
}

.circular_loader {
    -webkit-animation: rotate 3s linear infinite;
    animation: rotate 3s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.loader_path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 3s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@-webkit-keyframes color {
    0% {
        stroke: #00DFAB;
    }

    40% {
        stroke: #00DFAB;
    }

    66% {
        stroke: #00DFAB;
    }

    80%,
    90% {
        stroke: #00DFAB;
    }
}

@keyframes color {
    0% {
        stroke: #00DFAB;
    }

    40% {
        stroke: #00DFAB;
    }

    66% {
        stroke: #00DFAB;
    }

    80%,
    90% {
        stroke: #00DFAB;
    }
}

@media screen and (max-width: 380px) {
    .circular_loader {
        width: 108%;
        position: absolute;
        left: -10px;
        margin: auto;
    }
}

@media screen and (max-width: 330px) {
    .circular_loader {
        width: 112%;
        position: absolute;
        left: -14px;
        margin: auto;
    }
}