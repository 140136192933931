@import '../../../../styles/variable';

.section_container {
    background-color: $light_gray;
    padding: 24px 16px 8px;
}

.wrapper {
    position: relative;
    background-color: $white;
    border-radius: 12px;
    padding: 30px 24px 24px;
    margin: 0 auto;
}

.wr {
    padding: 30px 12px 24px;
}

@media  screen and (max-height: 829px) {
    .fixHeight2 {
        height: 55.5vh;
    }
}

@media  screen and (min-height: 830px) {
    .fixHeight {
        min-height: 54.5vh;
    }
}