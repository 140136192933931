@import '../../../styles/variable';

.title {
  font-family: $ff_b;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  white-space: break-spaces;
  color: $text;
}

.center {
  text-align: center;
}

.sx {
  font-size: 18px;
  line-height: 1.2;
}