@import '../../../styles/variable';

.item {
    background: $gray;
    border-radius: 8px;
    padding: 16px 16px 23px;
    margin-top: 24px;
}

.item:first-child {
    margin-top: 0;
}

.item_title {
    font-family: $ff_b;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;

    color: $text;

    margin-top: 9px;
}

.item_subtitle {
    font-family: $ff;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba($color: $text, $alpha: 0.7);
    margin-top: 5px;

}

.item_subtitle span {
    color: $text;
}

.item_price {
    font-family: $ff_b;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;

    margin-top: 11px;

    color: $text;
}

.item input[type="radio"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.radio {
    display: inline-block;
    height: 24px;
    width: 24px;
    background: #fff;
    border: 1px solid #D5DEEB;
    margin-right: 4px;
    border-radius: 12px;
    cursor: pointer;

    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.radio__active {
    background: $green;
    background-image: url('../../../../public/imgs/check.svg');
    background-size: 19px 13px;
    background-repeat: no-repeat;
    background-position: center;
    border-color: $green;
    cursor: pointer;
}